<template>
    <div class="fomeStyle_dy set-bunker-content" v-loading="loading">
        <div class="set-bunker-content-box">
            <table>
                <tbody>
                    <tr>
                        <th><span style="color:red">* </span> 料仓编号：</th>
                        <td>
                            <el-input v-model="lcbhMobel" placeholder="[自动生成]" readonly="readonly"></el-input>
                        </td>
                        <th><span style="color:red">* </span> 料仓号：</th>
                        <td>
                            <el-input v-model="lchMobel" placeholder="请输入"></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th><span style="color:red">* </span> 料仓名称：</th>
                        <td>
                            <el-input v-model="lcmcMobel" placeholder="[自动生成]"></el-input>
                        </td>
                        <th><span style="color:red">* </span> 料仓类型：</th>
                        <td>
                            <el-select
                                v-model="lclxModel"
                                value-key="code"
                                placeholder="请选择"
                                @change="getLclxChange"
                                :disabled="saveId ? true : false"
                            >
                                <el-option
                                    v-for="item in lclxList"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item"
                                >
                                </el-option>
                            </el-select>
                        </td>
                    </tr>
                    <tr>
                        <th><span style="color:red">* </span> 场站：</th>
                        <td>
                            <el-select
                                @change="getCzmcChange"
                                v-model="czmcModel"
                                placeholder="请选择"
                                value-key="station_id"
                                :disabled="saveId && !isAdjustment ? true : false"
                            >
                                <el-option
                                    v-for="item in stationListArray"
                                    :key="item.station_id"
                                    :label="item.name"
                                    :value="item"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <th><span style="color:red">* </span> 生产机组：</th>
                        <td>
                            <el-select
                                multiple
                                v-if="lclxModel.code !== 'F3-01-XN'"
                                v-model="scjzModel"
                                @change="scjzChange"
                                value-key="no"
                                placeholder="请选择"
                                :disabled="saveId && !isAdjustment ? true : false"
                            >
                                <el-option
                                    v-for="item in lineListArray"
                                    :key="item.no"
                                    :label="item.name"
                                    :value="item"
                                >
                                </el-option>
                            </el-select>
                            <el-input v-else readonly="readonly"></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th><span style="color:red">* </span> 料仓分类：</th>
                        <td>
                            <el-select
                                v-model="lcflModel"
                                value-key="code"
                                placeholder="请选择"
                                @change="getBusinessChange"
                                :disabled="saveId && !isAdjustment ? true : false"
                            >
                                <el-option
                                    v-for="item in getBusinessList"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <th><span style="color:red">* </span> 子类名称：</th>
                        <td>
                            <el-select
                                v-model="zlmcModel"
                                value-key="code"
                                placeholder="请选择"
                                @change="zlmcChangeFun"
                            >
                                <el-option
                                    v-for="item in zlmcListFun"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item"
                                >
                                </el-option>
                            </el-select>
                        </td>
                    </tr>
                    <tr>
                        <th>存放原材料：</th>
                        <td colspan="3">
                            <div
                                class="checkbox-div"
                                v-if="lcflModel.code === 'F3-01-XN-01' ||
                                    lcflModel.code === 'F3-01-XN-02' ||
                                    getLcflModelCode === 'F3-01-XN-01' ||
                                    getLcflModelCode === 'F3-01-XN-02'"
                            >
                                <el-checkbox-group @change="checkboxGroupChange" v-model="checkboxGroup" size="small">
                                    <el-checkbox
                                        v-for="(item, index) in cfyclListArray"
                                        :key="index"
                                        :label="item.dic_code +'&'+ item.name"
                                        :value="item.dic_code +'&'+ item.name"
                                        border
                                    >
                                        {{ item.name || item.material_name }}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </div>
                            <div
                                class="WL-radio"
                                v-else-if="cfyclListArray && lclxModel.code === 'F3-01-WL' ||
                                    lclxModelCode === 'F3-01-WL' ||
                                    getLcflModelCode === 'F3-01-XN-03' ||
                                    lcflModel.code === 'F3-01-XN-03'"
                            >
                                <el-radio-group @change="wlRadio" v-model="wlRadioSelect" size="small">
                                    <el-radio
                                        v-for="(item, index) in cfyclListArray"
                                        :key="index"
                                        :label="item"
                                        :value="item"
                                        border
                                    >
                                        {{ item.name || item.material_name }}
                                    </el-radio>
                                </el-radio-group>
                            </div>
                            <div class="special-water" v-else-if="lcflModel.dic_code === zlmcModel.dic_code">
                                {{ shuiName ? shuiName : ' ' }}
                            </div>
                            <div class="special-water" v-else-if="lclxModel.code !== 'F3-01-WL'">
                                {{ zlmcModel.name || shuiName }}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>货源地：</th>
                        <td colspan="3" @click="WakeUpWindow">
                            <b class="huoyuandi">
                                {{ sourceGoods.source_goods_name ? sourceGoods.source_goods_name : '鼠标点击选择货源地' }}
                            </b>
                        </td>
                    </tr>
                    <tr>
                        <th><span style="color:red">* </span> 状态：</th>
                        <td>
                            <el-select
                                v-model="stateModel"
                                placeholder="请选择"
                                @change="stateChange"
                            >
                                <el-option
                                    v-for="item in stateList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <th><span style="color:red">* </span> 开始使用时间：</th>
                        <td>
                            <el-date-picker
                                prefix-icon=" "
                                v-model="startTimeModel"
                                type="datetime"
                                @change="startTimeChange"
                                placeholder="选择日期时间"
                            >
                            </el-date-picker>
                        </td>
                    </tr>
                    <tr>
                        <th>最大库存容量：</th>
                        <td>
                            <el-input v-model="maxkcrlModel" placeholder="请输入"></el-input>
                        </td>
                        <th>安全容量：</th>
                        <td>
                            <el-input v-model="aqrlModel" placeholder="请输入"></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th>预警容量：</th>
                        <td>
                            <el-input v-model="yjrlModel" placeholder="请输入"></el-input>
                        </td>
                        <th>理论库存：</th>
                        <td>
                            <el-input v-model="llkcModel" placeholder="请输入"></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th>实际库存：</th>
                        <td>
                            <el-input v-model="sjkcModel" placeholder="请输入"></el-input>
                        </td>
                        <th>计量单位：</th>
                        <td>
                            <el-input v-model="jldwModel" :value="lclxModel" placeholder="请输入"></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th>备注：</th>
                        <td colspan="3">
                            <el-input v-model="bzModel" placeholder="请输入"></el-input>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="bottom-btn">
            <button @click="closeBtn">
                取消
            </button>
            <button v-if="viewStatus !== 1" @click="saveAlertContent">
                保存
            </button>
        </div>
    </div>
</template>

<script>
import _ from 'loadsh';

export default {
    data() {
        return {
            loading: false,
            lcbhMobel: '', // 料仓编号
            lchMobel: '', // 料仓号
            lcmcMobel: '', // 料仓名称
            shuiName: '',
            lclxList: [
                {
                    code: 'F3-01-WL',
                    name: '物理料仓',
                }, {
                    code: 'F3-01-XN',
                    name: '虚拟料仓',
                },
            ], // 料仓类型数组
            lclxModel: '', // 料仓类型
            czmcModel: '', // 场站名称
            scjzModel: [], // 选中生产机组
            lcflModel: '', // 料仓分类
            zlmcModel: '', // 子类名称
            stateModel: '', // 状态
            stateList: [
                {
                    value: 0,
                    label: '请选择',
                }, {
                    value: 1,
                    label: '启用',
                }, {
                    value: 2,
                    label: '停用',
                }, {
                    value: 3,
                    label: '维修',
                }, {
                    value: 4,
                    label: '失效',
                },
            ], // 状态
            startTimeModel: '', // 开始使用时间
            maxkcrlModel: '', // 最大库存容量
            aqrlModel: '', // 安全容量
            yjrlModel: '', // 预警容量
            llkcModel: '', // 理论库存
            sjkcModel: '', // 实际库存
            jldwModel: '吨', // 计量单位
            bzModel: '', // 备注
            stationListArray: [], // 获取场站信息对象
            activeStationCode: '', // 目前选中的场站code
            lineListArray: [], // 获取生产线数据
            getBusinessList: [], // 获取料仓分类业务列表
            zlmcListFun: [], // 子类名称数组
            cfyclListArray: [], // 存放原材料数组
            newCfyclListArray: [],
            checkboxGroup: [], // 存放原材料选择数组
            wlRadioSelect: [], // 单选项
            saveId: '', // 修改ID
            isAdjustment: false, // 调整ID
            getBunkerInfoData: {}, // 获取调整料仓信息
            getLcflModelCode: '', // 获取code
            getZlmcCode: '',
            getZlmcDicCode: '',
            getCzmcId: '',
            lclxModelCode: '',
            removeListYcl: [],
            radioActive: [],
            currentObjData: {},
            childTypeDicCode: {},
            // 计数
            count: 0,
            viewStatus: 0,
            checkboxGroupObj: [],
            sourceGoods: {},
            bmSecondHalf: {},
            getStockAlias: '',
            tiaozhengCount: 0,
            nobaocun: true,
        };
    },
    props: {
        extr: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    mounted() {
        this.saveId = this.extr.bunkerId;
        this.isAdjustment = this.extr.isAdjustment;
        this.viewStatus = this.extr.view;
        this.stateModel = this.stateList[1].value;
        this.gitStationList();
        this.getBunkerInfo();
    },
    watch: {
        checkboxGroup(newVal) {
            this.checkboxGroupObj = [];
            if (newVal.length > 0) {
                newVal.forEach(item => {
                    this.cfyclListArray.forEach(k => {
                        const newStr = k.dic_code + '&' + k.name;
                        if (item === newStr) {
                            this.checkboxGroupObj.push(k);
                        }
                    });
                });
            }
        },
    },
    methods: {
        // 货源地弹窗
        WakeUpWindow() {
            const dic_code = this.lcflModel.dic_code || this.getLcflDicCode;
            const queryStr = 'dict_code=' + dic_code;
            this.$toast(
                {
                    title: true,
                    text: '选择货源地',
                    type: 'eject',
                    t_url: 'tableList/index',
                    width: '14rem',
                    extr: {
                        code: {
                            TableCode: 'chose_source_goods',
                            QueryString: queryStr,
                        },
                        sureCallback: this.productNumCallback,

                    },
                }
            );
        },
        productNumCallback(data) {
            this.sourceGoods = data;
        },
        // 点击提交
        saveAlertContent() {
            if (this.nobaocun) {
                this.nobaocun = false;
                setTimeout(() => { this.nobaocun = true; }, 1000);
                if (!this.stateModel) {
                    this.$message.error('请选择状态');
                } else if (!this.startTimeModel) {
                    this.$message.error('请选择开始使用时间');
                } else if (this.isAdjustment) {
                    this.stockAdjust();
                } else {
                    this.bunkerManageSave();
                }
            }

        },
        // 存放原材料-单选
        wlRadio() {
            this.count++;
            const name = this.zlmcModel.name ? this.zlmcModel.name : this.zlmcModel;
            if (this.cfyclListArray.length > 0) {
                this.lcmcMobel = `${name}(${this.wlRadioSelect.name || this.wlRadioSelect.material_name})`;
            } else {
                this.lcmcMobel = `${name}${this.wlRadioSelect.name || this.wlRadioSelect.material_name}`;
            }
        },
        // 存放原材料-多选
        checkboxGroupChange(checks) {
            this.tiaozhengCount++;
            this.bmSecondHalf = {};
            this.checkboxGroupObj = this.cfyclListArray.filter(item => {
                const newStr = item.dic_code + '&' + item.name;
                if (this.checkboxGroup.length === 1 && newStr === checks[0]) {
                    this.bmSecondHalf = item;
                }
                const obj = this.checkboxGroup.find(o => {
                    const newStr = item.dic_code + '&' + item.name;
                    return newStr === o;
                });
                return obj;
            });
            const removeArr = _.differenceBy(this.cfyclListArray, this.checkboxGroupObj, 'dic_code');
            this.removeListYcl = removeArr.map(item => {
                return {
                    material_bg_code: item.code,
                    stock_id: this.saveId,
                    material_code: item.dic_code,
                    material_name: item.name,
                    material_group: item.parent_code,
                };
            });

            const lclxCode = this.lclxModel.code ? this.lclxModel.code : this.lclxModelCode;
            if (lclxCode === 'F3-01-XN') {
                if (this.count > 0) {
                    this.cunFangXuNiLc();
                } else if (this.checkboxGroupObj.length === this.cfyclListArray.length) {
                    this.lcmcMobel = this.zlmcModel;
                } else if (this.checkboxGroupObj.length === 0) {
                    this.lcmcMobel = '';
                } else {
                    const newArray = [];
                    this.checkboxGroupObj.forEach(item => {
                        newArray.push(item.material_name);
                    });
                    const stringList = newArray.join(',');
                    this.lcmcMobel = `${this.zlmcModel}(${stringList})`;
                }
            }
        },
        // 存放虚拟料仓
        cunFangXuNiLc() {
            if (this.checkboxGroupObj.length === this.cfyclListArray.length) {
                this.lcmcMobel = this.zlmcModel.name;
            } else if (this.checkboxGroupObj.length === 0) {
                this.lcmcMobel = '';
            } else {
                const newArray = [];
                this.checkboxGroupObj.forEach(item => {
                    newArray.push(item.name);
                });
                const stringList = newArray.join(',');
                this.lcmcMobel = `${this.zlmcModel.name}(${stringList})`;
            }
        },
        // 子类名称选择
        zlmcChangeFun(value, getname) {
            this.bmSecondHalf = {};
            this.checkboxGroup = [];
            this.checkboxGroupObj = [];
            if (value.parent_code === '207') {
                this.checkboxGroup.push(value);
            }
            this.removeListYcl = this.cfyclListArray;
            this.count++;
            this.zlmcModel = value;
            if (getname) {
                this.lcmcMobel = getname;
            } else {
                this.lcmcMobel = value.name;
            }
            this.cfyclListArray = [];
            const a = '-';
            const lcflCode = this.lcflModel.dic_code ? this.lcflModel.dic_code : this.getLcflDicCode;
            const lclxCode = this.lclxModel.code ? this.lclxModel.code : this.lclxModelCode;
            if (lcflCode.indexOf(a) > -1) {
                // 如果料仓分类有- 子类名称显示料仓分类 调用字典接口子类名称值为存放原材料
                this.getDictionariesList(this.zlmcModel.dic_code, 2);
            } else if (lclxCode === 'F3-01-XN' && lcflCode !== '207') {
                this.getlist(value.code, 2, 'huixian');
            } else {
                this.getDictionariesList(this.zlmcModel.code, 2);
                if (this.lclxCode === 'F3-01-WL' || lcflCode === '207') {
                    setTimeout(() => {
                        const name = this.zlmcModel.name ? this.zlmcModel.name : this.zlmcModel;
                        if (this.cfyclListArray.length > 0) {
                            this.lcmcMobel = `${name}(${this.wlRadioSelect.name})`;
                        } else {
                            this.lcmcMobel = `${name}${this.wlRadioSelect.name}`;
                        }
                    }, 100);
                }
            }
        },
        // 料仓分类选择
        getBusinessChange(value) {
            this.bmSecondHalf = {};
            this.count++;
            this.lcflModel = value;
            this.cfyclListArray = [];
            this.zlmcListFun = [];
            this.zlmcModel = {};
            this.checkboxGroup = [];
            this.checkboxGroupObj = [];
            this.lcmcMobel = '';
            const a = '-';
            if (this.lcflModel.dic_code.indexOf(a) > -1) {
                // 如果料仓分类有- 子类名称显示料仓分类
                this.zlmcListFun = [this.lcflModel];
                this.lcmcMobel = this.lcflModel.name;
                this.zlmcModel = this.lcflModel;
                this.childTypeDicCode = this.lcflModel.dic_code;
                if (this.czmcModel && this.lcflModel && this.lclxModel.code === 'F3-01-XN') {
                    this.fictitiousStockCode();
                }
                this.getDictionariesList(this.lcflModel.dic_code, 2);
                setTimeout(() => {
                    if (this.zlmcModel.dic_code === this.lclxModel.dic_code) {
                        const wlRad = this.wlRadioSelect ? this.wlRadioSelect.name : ' ';
                        if (this.cfyclListArray.length > 0) {
                            this.lcmcMobel = `${this.zlmcModel}(${wlRad})`;
                        } else {
                            this.lcmcMobel = `${this.zlmcModel}${wlRad}`;
                        }
                    }
                }, 200);
            } else if (this.lclxModel.code === 'F3-01-XN' && this.lcflModel.dic_code !== '207') {
                this.lcflXuNi();
            } else {
                this.getDictionariesList(this.lcflModel.dic_code, 1, this.lcflModel.name);
            }
            if (this.czmcModel && this.lcflModel && this.lclxModel.code === 'F3-01-WL') {
                this.physicsStockCode();
            }
            if (this.czmcModel && this.lcflModel && this.lclxModel.code === 'F3-01-XN' && this.lcflModel.dic_code === '207') {
                this.fictitiousStockCode();
            }
        },
        // 料仓分类选择-虚拟
        lcflXuNi() {
            let xuanzeString = '';
            if (this.count > 0) {
                xuanzeString = this.lcflModel.code;
            } else {
                xuanzeString = this.getLcflModelCode;
            }
            if (xuanzeString === 'F3-01-XN-01') {
                // F3-01-XN-01 选择的砂 F3-02写死传递
                this.getlist('F3-02', 1);
            } else if (xuanzeString === 'F3-01-XN-02') {
                // F3-01-XN-02 选择的石 F3-03写死传递
                this.getlist('F3-03', 1);
            } else if (xuanzeString === 'F3-01-XN-03') {
                // F3-01-XN-03 选择的水 特殊处理传递dic_code
                this.getDictionariesList(this.lcflModel.dic_code, 0);
            }
            if (this.czmcModel && this.lcflModel && this.lclxModel.code === 'F3-01-XN') {
                this.fictitiousStockCode();
            }
        },
        // 料仓类型选择
        getLclxChange(value) {
            this.bmSecondHalf = {};
            if (value.code) {
                this.lclxModel = value;
                this.lcbhMobel = '';
                this.czmcModel = '';
                this.lcflModel = '';
                this.zlmcModel = '';
                this.lcmcMobel = '';
                this.cfyclListArray = [];
                this.checkboxGroup = [];
                this.checkboxGroupObj = [];
                this.zlmcListFun = [];
                this.getlist(value.code, 0);
            } else {
                this.getlist(value, 0);
            }
        },
        // 场站名称选择
        getCzmcChange(value) {
            this.czmcModel = value;
            this.scjzModel = [];
            this.activeStationCode = value.station_id;
            if (this.activeStationCode) {
                this.getLineList();
            }
            if (this.czmcModel && this.lcflModel && this.lclxModel.code === 'F3-01-XN') {
                this.fictitiousStockCode();
            } else if (this.czmcModel && this.lcflModel && this.lclxModel.code === 'F3-01-WL') {
                this.physicsStockCode();
            }
        },
        // 生产机组名称选择
        scjzChange(value) {
            this.scjzModel = value;
        },
        // 开始使用时间
        startTimeChange(value) {
            this.startTimeModel = this.timeFormat(value);
        },
        // 状态
        stateChange(value) {
            this.stateModel = value;
        },
        // 格式化日期
        timeFormat(date) {
            const y = date.getFullYear(); // 年
            let m = date.getMonth() + 1; // 月
            let d = date.getDate(); // 日
            let h = date.getHours(); // 时
            let mi = date.getMinutes(); // 分
            let s = date.getSeconds(); // 秒
            m = m > 9 ? m : '0' + m;
            d = d > 9 ? d : '0' + d;
            h = h > 9 ? h : '0' + h;
            mi = mi > 9 ? mi : '0' + mi;
            s = s > 9 ? s : '0' + s;
            return `${y}-${m}-${d} ${h}:${mi}:${s}`;
        },
        // 物理料仓编号
        physicsStockCode() {
            this.$axios
                .post('/interfaceApi/procurement/stock/physics_stock_code', {
                    station_code: this.czmcModel.station_code,
                    type_code: this.lcflModel.code,
                })
                .then(res => {
                    this.lcbhMobel = res;
                })
                .catch(() => {
                    this.$message.error('获取失败');
                });
        },
        // 虚拟料仓编号
        fictitiousStockCode() {
            this.$axios
                .post('/interfaceApi/procurement/stock/fictitious_stock_code', {
                    station_code: this.czmcModel.station_code,
                    type_code: this.lcflModel.code,
                })
                .then(res => {
                    this.lcbhMobel = res;
                })
                .catch(() => {
                    this.$message.error('获取失败');
                });
        },
        closeBtn() {
            this.$parent.hide();
        },
        // 物理料仓-调整
        // eslint-disable-next-line complexity
        stockAdjust() {
            this.loading = true;
            let saveList = [];
            let removeList = [];
            // 原材料业务分组数据（主表）
            let new_material_bg_code = '';
            let new_material_name = '';
            let new_material_dic_code = '';
            // 多选
            const lclxCode = this.lclxModel.code ? this.lclxModel.code : this.lclxModelCode;
            if (lclxCode === 'F3-01-XN') {
                if (this.count > 0) {
                    if (this.checkboxGroupObj.length > 0) {
                        this.checkboxGroupObj.forEach(item => {
                            let obj = {};
                            obj = {
                                material_bg_code: item.code,
                                stock_id: this.saveId,
                                material_code: item.dic_code,
                                material_name: item.name,
                                material_group: item.parent_code,
                            };
                            saveList.push(obj);
                        });
                        removeList = this.removeListYcl;
                    } else {
                        saveList = [];
                    }
                } else {
                    saveList = _.intersectionBy(this.cfyclListArray, this.checkboxGroupObj, 'material_name');
                    removeList = _.differenceBy(this.cfyclListArray, this.checkboxGroupObj, 'material_name');
                }
            // 单选
            } else if (this.count > 0) {
                const obj = {
                    stock_id: this.saveId,
                    material_code: this.wlRadioSelect ? this.wlRadioSelect.code : '',
                    material_name: this.wlRadioSelect ? this.wlRadioSelect.name : '',
                    material_group: this.wlRadioSelect ? this.wlRadioSelect.parent_code : '',
                };
                saveList.push(obj);
                removeList = this.radioActive;
            } else {
                saveList = this.radioActive;
            }
            let stockAlias = '';
            let zlmcName = '';
            let wlZlmcSpecs = '';
            if (this.checkboxGroupObj.length > 0) {
                zlmcName = this.zlmcModel.name ? this.zlmcModel.name : this.zlmcModel;
                wlZlmcSpecs = this.wlRadioSelect.name ? this.wlRadioSelect.name : this.wlRadioSelect.material_name;
            } else {
                zlmcName = this.zlmcModel.name || this.zlmcModel;
                if (typeof this.wlRadioSelect !== 'undefined' && this.wlRadioSelect.name !== '') {
                    wlZlmcSpecs = this.wlRadioSelect.name;
                } else if (typeof this.wlRadioSelect !== 'undefined' && this.wlRadioSelect.material_name !== '') {
                    wlZlmcSpecs = this.wlRadioSelect.material_name;
                } else {
                    saveList = [];
                    wlZlmcSpecs = '';
                }
            }
            if (lclxCode === 'F3-01-XN') {
                stockAlias = `${zlmcName},${zlmcName}`;
            } else {
                stockAlias = `${zlmcName},${wlZlmcSpecs}`;
            }

            let childDicCode;
            if (this.zlmcModel.name === '水泥') {
                childDicCode = this.zlmcModel.code;
            } else if (this.zlmcModel.dic_code === '') {
                childDicCode = '';
            } else {
                childDicCode = this.zlmcModel.dic_code || this.zlmcModel.code || this.newChildTypeDicCoderes;
            }

            // 场站名称
            let productionUnitNames = '';
            // 场站id
            let productionUnitIds = '';
            if (this.scjzModel.length > 0) {
                this.scjzModel.forEach((item, index) => {
                    if (index === this.scjzModel.length - 1) {
                        productionUnitNames += item.name;
                        productionUnitIds += item.no;
                    } else {
                        productionUnitNames += item.name + ',';
                        productionUnitIds += item.no + ',';
                    }
                });
            }

            // 有选中的原材料，主表原材料业务数据取选中的第一个原材料的业务数据
            if (saveList.length > 0) {
                new_material_bg_code = saveList[0].material_bg_code;
                new_material_name = saveList[0].material_name;
                new_material_dic_code = saveList[0].material_code;
            }
            this.$axios
                .post('/interfaceApi/procurement/stock/adjust', {
                    stock_id: this.saveId,
                    stock: {
                        source_goods_code: this.sourceGoods.source_goods_code,
                        source_goods_name: this.sourceGoods.source_goods_name,
                        source_id: this.sourceGoods.id,
                        id: this.saveId,
                        stock_alias: stockAlias,
                        stock_code: this.lcbhMobel,
                        stock_name: this.lcmcMobel,
                        stock_no: this.lchMobel,
                        material_dic_code: new_material_dic_code,
                        material_name: new_material_name,
                        category_name: this.lclxModel.name || this.lclxModel,
                        category_code: this.lclxModel.code || this.lclxModelCode,
                        station_id: this.czmcModel.station_id || this.getCzmcId,
                        station_name: this.czmcModel.name || this.czmcModel,
                        type_name: this.lcflModel.name || this.lcflModel,
                        type_code: this.lcflModel.code || this.getLcflModelCode,
                        type_dic_code: this.lcflModel.dic_code || this.getLcflDicCode,
                        child_type_name: this.zlmcModel.name || this.zlmcModel,
                        child_type_code: this.zlmcModel.dic_code || this.zlmcModel.code || this.getZlmcCode,
                        child_type_dic_code: childDicCode,
                        team_id: productionUnitIds,
                        team_name: productionUnitNames,
                        start_time: this.startTimeModel,
                        stock_state: this.stateModel,
                        volume_max: this.maxkcrlModel || 0,
                        volume_safe: this.aqrlModel || 0,
                        volume_warn_today: this.yjrlModel || 0,
                        volume_theory: this.llkcModel || 0,
                        volume_actual: this.sjkcModel || 0,
                        measure_unit: this.jldwModel,
                        remarks: this.bzModel,
                        material_bg_code: new_material_bg_code,
                    },
                    materials: saveList,
                    materials_deleted: removeList,
                })
                .then(res => {
                    this.saveId = res;
                    this.$message.success('成功');
                    window.hackReset();
                    this.$parent.hide();
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取调整料仓信息
        getBunkerInfo() {
            this.loading = true;
            this.$axios
                .get(`/interfaceApi/procurement/info/${this.saveId}`)
                .then(async res => {
                    if (res.materials && res.stock) {
                        this.getBunkerInfoData = res.stock;
                        this.sourceGoods = {
                            source_goods_code: res.stock.source_goods_code,
                            source_goods_name: res.stock.source_goods_name,
                            id: res.stock.source_id,
                        };
                        this.getStockAlias = res.stock.stock_alias;

                        this.newChildTypeDicCoderes = res.stock.child_type_dic_code;

                        this.cfyclListArray = res.materials;
                        this.newCfyclListArray = res.materials;
                        this.lcbhMobel = this.getBunkerInfoData.stock_code;
                        this.lchMobel = this.getBunkerInfoData.stock_no;
                        this.lcmcMobel = this.getBunkerInfoData.stock_name;
                        // 料仓类型
                        this.lclxModel = this.getBunkerInfoData.category_name;
                        this.lclxModelCode = this.getBunkerInfoData.category_code;
                        // 场站名称赋值
                        this.czmcModel = this.getBunkerInfoData.station_name;
                        this.getCzmcId = this.getBunkerInfoData.station_id;
                        // 场站ID
                        this.activeStationCode = this.getBunkerInfoData.station_id;
                        // 初始化有选中场站，拉取场站下生产机组
                        if (this.activeStationCode) {
                            this.getLineList();
                        }
                        // 料仓分类赋值
                        this.lcflModel = this.getBunkerInfoData.type_name;
                        // 料仓分类分类编码
                        this.getLcflModelCode = this.getBunkerInfoData.type_code;
                        // 料仓分类字典编码
                        this.getLcflDicCode = this.getBunkerInfoData.type_dic_code;
                        // 初始化有选中料仓分类，拉取料仓分类下子类
                        if (this.getLcflDicCode) {
                            if (this.getLcflDicCode.indexOf('-') > -1) {
                                // 如果料仓分类有- 子类名称显示料仓分类
                                this.zlmcListFun = [{
                                    name: this.lcflModel,
                                    code: this.getLcflModelCode,
                                    dic_code: this.getLcflDicCode,
                                }];
                            } else {
                                await this.getDictionariesList(this.getLcflDicCode, 1);
                            }
                        }
                        // 子类名称赋值
                        this.zlmcModel = this.getBunkerInfoData.child_type_name;
                        // 子类编码
                        this.getZlmcCode = this.getBunkerInfoData.child_type_code;
                        // 子类字段编码
                        this.getZlmcDicCode = this.getBunkerInfoData.child_type_dic_code;
                        // 开始时间
                        this.startTimeModel = this.getBunkerInfoData.start_time;
                        // 状态
                        this.stateModel = this.getBunkerInfoData.stock_state;
                        // 最大库存容量
                        this.maxkcrlModel = this.getBunkerInfoData.volume_max;
                        // 安全容量
                        this.aqrlModel = this.getBunkerInfoData.volume_safe;
                        // 预警容量
                        this.yjrlModel = this.getBunkerInfoData.volume_warn_today;
                        // 理论库存
                        this.llkcModel = this.getBunkerInfoData.volume_theory;
                        // 实际库存
                        this.sjkcModel = this.getBunkerInfoData.volume_actual;
                        // 计量单位
                        this.jldwModel = this.getBunkerInfoData.measure_unit;
                        // 备注
                        this.bzModel = this.getBunkerInfoData.remarks;
                        // 处理生产机组数据
                        let new_team_name = [];
                        let new_team_id = [];
                        if (this.getBunkerInfoData.team_name && this.getBunkerInfoData.team_name !== '') {
                            new_team_name = this.getBunkerInfoData.team_name.split(',');
                            new_team_id = this.getBunkerInfoData.team_id.split(',');
                        }
                        new_team_name.forEach((item, index) => {
                            this.scjzModel.push({
                                name: item,
                                no: new_team_id[index],
                            });
                        });

                        if (this.lclxModelCode === 'F3-01-WL' || this.getLcflModelCode === 'F3-01-XN-03') {
                            this.radioActive = res.materials;
                        }
                        if (this.isAdjustment) {
                            this.getLclxChange(this.lclxModelCode);
                        }
                        if (this.lclxModelCode === 'F3-01-XN' && this.getLcflModelCode !== 'F3-01-XN-03') {
                            if (this.getLcflModelCode === 'F3-01-XN-01') {
                                // F3-01-XN-01 选择的砂 F3-02写死传递
                                this.getlist('F3-02', 1, 'tiaoZheng');
                            } else if (this.getLcflModelCode === 'F3-01-XN-02') {
                                // F3-01-XN-02 选择的石 F3-03写死传递
                                this.getlist('F3-03', 1, 'tiaoZheng');
                            }
                        } else if (this.lclxModelCode === 'F3-01-WL' || this.getLcflModelCode === 'F3-01-XN-03') {
                            const a = '-';
                            const lcflCode = this.lcflModel.dic_code ? this.lcflModel.dic_code : this.getLcflDicCode;
                            if (lcflCode.indexOf(a) > -1) {
                                const obj = {
                                    name: this.zlmcModel,
                                    code: this.getZlmcCode,
                                    dic_code: this.getZlmcDicCode,
                                };
                                this.currentObjData = obj;
                                // 如果料仓分类有- 子类名称显示料仓分类 调用字典接口子类名称值为存放原材料
                                this.getDictionariesList(this.getLcflDicCode, 2);
                            } else {
                                const obj = {
                                    name: this.zlmcModel,
                                    code: this.getZlmcCode,
                                    dic_code: this.getZlmcDicCode,
                                };
                                this.currentObjData = obj;
                                this.zlmcChangeFun(obj, this.getBunkerInfoData.stock_name);
                            }
                        }
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 料仓管理-保存
        // eslint-disable-next-line complexity
        bunkerManageSave() {
            this.loading = true;
            let saveList = [];
            let removeList = [];
            // 原材料业务分组数据（主表）
            let new_material_bg_code = '';
            let new_material_name = '';
            let new_material_dic_code = '';
            // 多选
            const lclxCode = this.lclxModel.code ? this.lclxModel.code : this.lclxModelCode;
            const wlDicName = this.wlRadioSelect ? this.wlRadioSelect.name : '';
            let isShui = true;
            if (wlDicName) {
                isShui = wlDicName.indexOf('水') === -1;
            }
            if (lclxCode === 'F3-01-XN' && isShui) {
                if (this.count > 0) {
                    if (this.checkboxGroupObj.length > 0) {
                        this.checkboxGroupObj.forEach(item => {
                            let obj = {};
                            obj = {
                                material_bg_code: item.code,
                                stock_id: this.saveId,
                                material_code: item.dic_code || item.code,
                                material_name: item.name,
                                material_group: item.parent_code,
                            };
                            saveList.push(obj);
                        });
                        removeList = this.removeListYcl;
                    } else {
                        saveList = [];
                    }
                } else {
                    saveList = _.intersectionBy(this.cfyclListArray, this.checkboxGroupObj, 'material_name');
                    removeList = _.differenceBy(this.cfyclListArray, this.checkboxGroupObj, 'material_name');
                }
            } else if (lclxCode === 'F3-01-XN' && isShui) {
                if (this.count > 0) {
                    if (this.checkboxGroupObj.length > 0) {
                        this.checkboxGroupObj.forEach(item => {
                            let obj = {};
                            obj = {
                                material_bg_code: item.code,
                                stock_id: this.saveId,
                                material_code: item.dic_code || item.code,
                                material_name: item.name,
                                material_group: item.parent_code,
                            };
                            saveList.push(obj);
                        });
                        removeList = this.removeListYcl;
                    } else {
                        saveList = [];
                    }
                } else {
                    saveList = _.intersectionBy(this.cfyclListArray, this.checkboxGroupObj, 'material_name');
                    removeList = _.differenceBy(this.cfyclListArray, this.checkboxGroupObj, 'material_name');
                }
            } else if (this.count > 0) {
                if (this.cfyclListArray.length > 0) {
                    const obj = {
                        stock_id: this.saveId,
                        material_code: this.wlRadioSelect.code,
                        material_name: this.wlRadioSelect.name,
                        material_group: this.wlRadioSelect.parent_code,
                    };
                    saveList.push(obj);
                    removeList = this.radioActive;
                } else {
                    saveList = [];
                }
            } else {
                saveList = this.radioActive;
            }
            // 别名处理
            let stockAlias = '';
            let zlmcName = '';
            let wlZlmcSpecs = '';
            // 208 外加剂
            if (this.lclxModelCode === 'F3-01-WL' || this.lclxModel.code === 'F3-01-WL' && this.zlmcModel.parent_code === '208') {
                zlmcName = this.zlmcModel.name;
                if (this.wlRadioSelect) {
                    wlZlmcSpecs = this.wlRadioSelect.name ? this.wlRadioSelect.name : this.wlRadioSelect.material_name;
                }
            } else if (this.lclxModelCode === 'F3-01-WL' || this.lclxModel.code === 'F3-01-WL' && this.checkboxGroupObj.length > 0) {
                zlmcName = this.zlmcModel.name ? this.zlmcModel.name : this.zlmcModel;
                wlZlmcSpecs = this.wlRadioSelect.name ? this.wlRadioSelect.name : this.wlRadioSelect.material_name;
            } else {
                zlmcName = this.zlmcModel.name ? this.zlmcModel.name : this.zlmcModel;
                if (this.wlRadioSelect) {
                    wlZlmcSpecs = this.wlRadioSelect.name ? this.wlRadioSelect.name : this.wlRadioSelect.material_name;
                }
            }
            if (lclxCode === 'F3-01-XN' && this.zlmcModel.name !== '水' ) {
                if (this.tiaozhengCount > 0 || !this.saveId) {
                    if (this.bmSecondHalf.name) {
                        stockAlias = `${zlmcName},${this.bmSecondHalf.name}`;
                    } else {
                        stockAlias = `${zlmcName},${zlmcName}`;
                    }
                } else {
                    stockAlias = this.getStockAlias;
                }
            } else {
                stockAlias = `${zlmcName},${wlZlmcSpecs || zlmcName}`;
            }
            let childDicCode;
            if (this.zlmcModel.name === '水泥') {
                childDicCode = this.zlmcModel.code;
            } else if (this.zlmcModel.dic_code === '') {
                childDicCode = '';
            } else {
                childDicCode = this.zlmcModel.dic_code || this.zlmcModel.code || this.newChildTypeDicCoderes;
            }

            // 场站名称
            let productionUnitNames = '';
            // 场站id
            let productionUnitIds = '';
            if (this.scjzModel.length > 0) {
                this.scjzModel.forEach((item, index) => {
                    if (index === this.scjzModel.length - 1) {
                        productionUnitNames += item.name;
                        productionUnitIds += item.no;
                    } else {
                        productionUnitNames += item.name + ',';
                        productionUnitIds += item.no + ',';
                    }
                });
            }
            // 有选中的原材料，主表原材料业务数据取选中的第一个原材料的业务数据
            if (saveList.length > 0) {
                new_material_bg_code = saveList[0].material_bg_code;
                new_material_name = saveList[0].material_name;
                new_material_dic_code = saveList[0].material_code;
            }
            this.$axios
                .post('/interfaceApi/procurement/stock/save', {
                    stock: {
                        source_goods_code: this.sourceGoods.source_goods_code,
                        source_goods_name: this.sourceGoods.source_goods_name,
                        source_id: this.sourceGoods.id,
                        id: this.saveId,
                        stock_alias: stockAlias,
                        stock_code: this.lcbhMobel,
                        stock_name: this.lcmcMobel,
                        stock_no: this.lchMobel,
                        category_name: this.lclxModel.name || this.lclxModel,
                        category_code: this.lclxModel.code || this.lclxModelCode,
                        station_id: this.czmcModel.station_id || this.getCzmcId,
                        station_name: this.czmcModel.name || this.czmcModel,
                        type_name: this.lcflModel.name || this.lcflModel,
                        type_code: this.lcflModel.code || this.getLcflModelCode,
                        type_dic_code: this.lcflModel.dic_code || this.getLcflDicCode,
                        child_type_name: this.zlmcModel.name || this.zlmcModel,
                        child_type_code: this.zlmcModel.code || this.zlmcModel.dic_code,
                        team_id: productionUnitIds,
                        team_name: productionUnitNames,
                        child_type_dic_code: childDicCode,
                        start_time: this.startTimeModel,
                        stock_state: this.stateModel,
                        volume_max: this.maxkcrlModel || 0,
                        volume_safe: this.aqrlModel || 0,
                        volume_warn_today: this.yjrlModel || 0,
                        volume_theory: this.llkcModel || 0,
                        volume_actual: this.sjkcModel || 0,
                        measure_unit: this.jldwModel,
                        remarks: this.bzModel,
                        material_dic_code: new_material_dic_code,
                        material_name: new_material_name,
                        material_bg_code: new_material_bg_code,
                    },
                    materials: saveList,
                    materials_deleted: removeList,
                })
                .then(res => {
                    this.saveId = res;
                    this.$message.success('成功');
                    window.hackReset();
                    this.$parent.hide();
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取字典列表
        async getDictionariesList(value, status, name) {
            // status: 0  虚拟料仓 料仓分类为水的时候
            // status: 1  物理料仓 料仓分类选择
            // status: 2  物理料仓 子类名称选择
            await this.$axios
                .get(`/interfaceApi/BaseInfo/dictionary_config/getlist?parent_code=${value}&type=2`)
                .then(res => {
                    if (this.isAdjustment) {
                        this.wlRadioSelect = res[0];
                    }
                    if (status === 1) {
                        this.childTypeDicCode = value;
                    }
                    if (status === 0 || status === 1) {
                        this.zlmcListFun = res;
                        if (name === '水泥' || name === '水') {
                            this.zlmcModel = res[0];
                            this.zlmcChangeFun(this.zlmcModel);
                        }
                    } else {
                        this.cfyclListArray = res;
                        if (this.lclxModelCode === 'F3-01-WL' || this.getLcflModelCode === 'F3-01-XN-03') {
                            this.cfyclListArray.forEach((item, index) => {
                                if (item.code === this.radioActive[0].material_code && item.name === this.radioActive[0].material_name) {
                                    this.wlRadioSelect = res[index];
                                }
                            });
                        } else {
                            this.wlRadioSelect = res[0];
                        }
                        if (this.lclxModel.code === 'F3-01-WL' || res[0].code.indexOf('207') !== -1) {
                            setTimeout(() => {
                                const name = this.zlmcModel.name ? this.zlmcModel.name : this.zlmcModel;
                                if (this.cfyclListArray.length > 0) {
                                    if (this.wlRadioSelect) {
                                        this.lcmcMobel = `${name}(${this.wlRadioSelect.name})`;
                                    } else {
                                        this.lcmcMobel = `${name}`;
                                    }
                                } else if (this.wlRadioSelect) {
                                    this.lcmcMobel = `${name}${this.wlRadioSelect.name}`;
                                } else {
                                    this.lcmcMobel = `${name}`;
                                }
                            }, 100);
                        }
                    }
                    this.$forceUpdate();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 拉取业务分组列表
        getlist(value, status, ist) {
            this.checkboxGroup = [];
            this.checkboxGroupObj = [];
            // status选择列表状态
            // 0：第一次选择料仓分类
            // 1：料仓分类选择砂、石
            // 2：子类名称选择
            this.$axios
                .get(`/interfaceApi/BaseInfo/businessgroup_config/getlist?parent_code=${value}`)
                .then(res => {
                    if (status === 0) {
                        this.getBusinessList = res;
                    } else if (status === 1) {
                        this.zlmcListFun = res;
                        if (ist === 'tiaoZheng') {
                            const obj = {
                                name: this.zlmcModel,
                                code: this.getZlmcCode,
                                dic_code: this.getZlmcDicCode,
                            };
                            this.zlmcChangeFun(obj, this.getBunkerInfoData.stock_name);
                        }
                    } else if (status === 2) {
                        if (ist === 'huixian' && this.saveId) {
                            res.forEach(item => {
                                const row = this.newCfyclListArray.filter(k => item.dic_code === k.material_code && item.name === k.material_name);
                                if (row.length > 0) {
                                    this.checkboxGroup.push(item.dic_code + '&' + item.name);
                                }
                            });
                            this.cfyclListArray = res;
                        } else {
                            this.cfyclListArray = res;
                            this.checkboxGroup = this.cfyclListArray.map(item => {
                                return item.dic_code + '&' + item.name;
                            });
                        }
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取生产线信息
        getLineList() {
            this.$axios
                .get(`/interfaceApi/BaseInfo/stationmanger/get_line_list?stationId=${this.activeStationCode}`)
                .then(res => {
                    this.lineListArray = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取场站信息
        gitStationList() {
            this.stationListArray = [];
            const station_IDs = this.$takeTokenParameters('StationIds').split(',');
            this.$axios
                .get('/interfaceApi/BaseInfo/stationmanger/get_station_list')
                .then(res => {
                    if (res.length > 0) {
                        this.stationListArray = res.filter(item => station_IDs.indexOf(item.station_id) > -1);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });

        },
    },
};

</script>
<style lang='stylus'>
button
    cursor:pointer;
.set-bunker-content
    background #fff
    width: 100%;
    height: 100%;
    overflow: hidden;
    position relative
.set-bunker-content-box
    table
        td
            .huoyuandi
                display block
                font-weight 400
                height 0.36rem
                line-height 0.34rem
                background #fff
                border 1px solid #DCDFE6
                padding 0 15px
                border-radius 2px
.bottom-btn
    width 100%
    display flex
    justify-content center
    align-items center
    position absolute
    bottom .14rem
    button
        width 2.5rem
        height .44rem
        border none
        background none
        font-size .2rem
        &:first-child
            border 1px solid #3577cd
            color #3577cd
            margin-right 1rem
        &:last-child
            border 1px solid #3577cd
            background #3577cd
            color #fff
</style>
